'use client';

import { DeepLinkData, SessionData } from '../../global';
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
interface Props {
  children: ReactNode;
}
const branchKey = process.env.NEXT_PUBLIC_BRANCH_KEY;
interface ExtendedSessionData extends SessionData {
  data_parsed: {
    referrer_code: string;
  };
}
const BranchIoContext = createContext<{
  data?: ExtendedSessionData | null;
  createBranchLink?: (params: DeepLinkData) => Promise<unknown>;
}>({});
export const createBranchLink = async (params: branch.DeepLinkData) => {
  if (typeof window !== 'undefined') {
    const branch = (await import('branch-sdk')).default;
    // console.log(branch);

    return new Promise((resolve, reject) => {
      branch.link(params, (err, link) => {
        if (err) {
          reject(err);
        } else {
          resolve(link);
        }
      });
    });
  }
};
const BranchIoProvider: FC<Props> = ({
  children
}) => {
  const [branchData, setBranchData] = useState<ExtendedSessionData | null>();
  useEffect(() => {
    async function initAndFetch() {
      const branch = (await import('branch-sdk')).default;
      branch.init(branchKey ?? '');
      branch.data(function (err, data) {
        if (err) {
          console.error('Branch initialization error:', err);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setBranchData(data);
          // console.log('Branch initialized successfully:', data);
        }
      });
    }
    initAndFetch();
  }, []);
  return <BranchIoContext.Provider value={{
    data: branchData,
    createBranchLink
  }} data-sentry-element="unknown" data-sentry-component="BranchIoProvider" data-sentry-source-file="branch-io-provider.tsx">
      {children}
    </BranchIoContext.Provider>;
};
const useBranchIo = () => useContext(BranchIoContext);
export { BranchIoProvider, useBranchIo };